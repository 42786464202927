import React, { useState } from 'react';
import axios from 'axios';
import './App.css';
import Greeting from './components/Loading';
import img1 from './images/13.png';
import Scroll from './components/Scroll';
import pptxgen from 'pptxgenjs';
import { motion } from 'framer-motion';


function App() {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [photos, setPhotos] = useState([]);
  

  async function generate() {
    setAnswer('loading...');
    try {
      const questionWithSlides = `give me 5 slides content for the topic ${question} with each slide one title, one paragraph of max 110 words (**Paragraph:**) , 3 bullet points of max 30 words (**Bullet Points:**)`;
  
      // Construct the API URL with your API key using template literals
      const apiUrl = `https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash:generateContent?key=${process.env.REACT_APP_GEMINI_API_KEY}`;
  
      // Fetch slide content
      const response = await axios.post(
        apiUrl,
        {
          contents: [{ parts: [{ text: questionWithSlides }] }],
        }
      );

      const content = response.data.candidates[0].content.parts[0].text;
      console.log(content);
      setAnswer(content);


      // Fetch photos
     
      const url = `https://api.pexels.com/v1/search?query=${question}&per_page=5`;
      const photoResponse = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PEXELS_API_KEY}`,
        },
      });
      setPhotos(photoResponse.data.photos);

    } catch (error) {
      console.error('Error fetching data:', error);
      setAnswer('Error fetching data. Please try again.');
    }
  }

  const parseSlides = (text, photos) => {
    // Split the text by slide markers and remove the first empty element (if any)
    const slides = text.split(/\*\*Slide \d+: /).slice(1);
    
    return slides.map((slide, index) => {
      // Split slide content by lines and filter out empty lines
      const [title, ...rest] = slide.split('\n').filter(line => line.trim());
  
      // Find indices for paragraph and bullet points markers
      const paragraphIndex = rest.findIndex(line => line.startsWith('**Paragraph:**'));
      const bulletPointsIndex = rest.findIndex(line => line.startsWith('**Bullet Points:**'));
  
      // Extract paragraph text
      let paragraph = '';
      if (paragraphIndex !== -1) {
        // Check if paragraph text is on the same line as the marker
        if (rest[paragraphIndex].length > '**Paragraph:**'.length) {
          paragraph = rest[paragraphIndex].substring('**Paragraph:**'.length).trim();
        }
  
        // Gather text from following lines if not on the same line
        for (let i = paragraphIndex + 1; i < rest.length; i++) {
          if (i === bulletPointsIndex || rest[i].startsWith('**')) {
            break; // Stop if another marker is found
          }
          paragraph += (paragraph ? ' ' : '') + rest[i].trim();
        }
      }
  
      // Extract bullet points text
      const bulletPoints = bulletPointsIndex !== -1
        ? rest.slice(bulletPointsIndex + 1).filter(line => !line.startsWith('**')).map(line => line.replace('*', '').trim())
        : [];
  
      // Return the slide object
      return {
        title: title.replace(/\*\*/g, ''),
        paragraph,
        image: photos[index]?.src.medium, // Assuming you have a medium-sized image URL in photos
        bulletPoints,
      };
    });
  };
  
  function generatePPTX(slides) {
    const pptx = new pptxgen();
  
    slides.forEach((slide, index) => {
      const { title, paragraph, image, bulletPoints } = slide;
  
      const slideInstance = pptx.addSlide();
      slideInstance.background = { fill: '#f3e5f5' };
      
  
      // Add title
      slideInstance.addText(title, { 
        x: 1, 
        y: 0.7, 
        fontSize: 25, 
        bold: true, 
        fontFace: 'Kanit', 
        align: 'center',
        color: '000000'
      });
  
      // Add image
      slideInstance.addImage({ 
        path: image, 
        x: 0.5, 
        y: 1.2, // Adjusted y position for image
        w: 5, 
        h: 3 
      });
  
      // Add paragraph
      slideInstance.addText(paragraph, { 
        x: 0.4, 
        y: 5, // Adjusted y position for paragraph
        w: 9, 
        fontSize:11, 
        fontFace: 'PT Serif', 
        align: 'left',
        color: '000000'
      });
  
      // Add bullet points
      bulletPoints.forEach((point, i) => {
        const gap = 0.4; // Adjust this value as needed for the desired gap between bullet points
        slideInstance.addText(`• ${point}`, { 
          x: 5.5, 
          y: 1.8 + i * (0.5 + gap), // Adjusted y position for each bullet point with a gap
          w: 4, 
          fontSize: 15, 
          fontFace: 'Bitter', 
          italic: true, 
          color: '000000',
          margin: 30
        });
      });
    });
  
    pptx.writeFile({ fileName: 'GeneratedSlides' });
  }
  

  return (
    <div className="app">
      <motion.div
      initial={{ opacity: 0, y:  0 }}  // Initial animation properties
      whileInView={{ opacity: 1, y: 0}}    // Animation properties after component mounts
      transition={{ duration: 2 }}        // Transition configuration
      className="glitch-wrapper"
    >
   <div class="glitch" data-glitch="AI SLIDE STUDIO">AI SLIDE STUDIO</div>

    </motion.div>

   
      <div className="text-container">
      
      <div>

      <span className='note'>"Note: If your desired output or presentation layout doesn't come out as expected on the first try, feel free to resubmit the same topic. Refining and iterating often lead to achieving the perfect result."</span>
      </div>
      <div>
      <span className='max'> (Topic: Max 50 words)</span>
      </div>

      </div>
      <div className="searchb">
        <div className="container">
          <div className="search-container">
          <input
  className="input"
  type="text"
  value={question}
  onChange={(e) => {
    // Restrict input to 100 characters
    const inputText = e.target.value;
    if (inputText.length <= 50) {
      setQuestion(inputText);
    }
  }}
  placeholder="Enter your topic here"
/>


            <svg viewBox="0 0 24 24" className="search__icon">
              <g>
                <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
              </g>
            </svg>
          </div>
        </div>
      </div>

      <div className="bt">
        <button className="button" onClick={generate}>
          <svg fill="currentColor" viewBox="0 0 24 24" className="icon">
            <path clipRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" fillRule="evenodd"></path>
          </svg>
          Generate Slides
        </button>
        
      </div>
      <div className="img-cont">
        <motion.img  initial={{ opacity: 0, x:-200 }}  // Initial animation properties
      whileInView={{ opacity: 1, x:0}}    // Animation properties after component mounts
      transition={{ duration: 2 }} src={img1} alt="" className='img' />
      </div>
      <div className="fig">
        <Scroll/>
        
      </div>

      {answer === 'loading...' ? (
        <div className="jj">
          <Greeting />
        </div>
      ) : ( 
        
       <>
  <div className="outside">
          {parseSlides(answer, photos).map((slide, index) => (
            <div key={index} className="slides-container">
              <div className="slide">
                <h2>{`Slide ${index + 1}: ${slide.title}`}</h2>
                <div className="slidecont">
                  <img src={slide.image} alt={`Slide ${index + 1} image`} className="images" />
                  <ul className="bullet-points">
                    {slide.bulletPoints.map((point, i) => (
                      <li key={i}>{point}</li>
                    ))}
                  </ul>
                </div>
                <p className="paragraph">{slide.paragraph}</p>
              </div>
            </div>
          ))}
          </div>
                 <div className="downloadbtn">
          <button className="button" onClick={() => generatePPTX(parseSlides(answer, photos))}>
            <svg fill="currentColor" viewBox="0 0 24 24" className="icon">
              <path clipRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" fillRule="evenodd"></path>
            </svg>
            Download Presentation
          </button>
          </div>
        </>
      )}
    </div>
    
    
  );
}

export default App;
