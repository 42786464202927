
import React from 'react';
import './Scroll.css';
import NumberCounter from 'number-counter';

const Scroll = () => {
  return (


<div className="figures">
<div>
  <span> <NumberCounter end={50000 }  start={0} delay='4' preFix='+ '  /> </span>
<span>SLIDES GENERATED</span>
</div>
<div>
  <span><NumberCounter end={5000} start={0} delay='4' preFix='+' /></span>
  <span>PRESENTATIONS MADE </span>
</div>
<div>
  <span><NumberCounter end={5000} start={0} delay='4' preFix='+' /></span>
  <span>TOPICS INPUTTED</span>
</div>
</div>
  );
};

export default Scroll;
